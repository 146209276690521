import { callService } from "./callService";
import uiActions from "../redux/actions/ui.actions";

/**
 * Realiza una llamada a un servicio y maneja la retroalimentación del usuario.
 *
 * @param {string} serviceName - Nombre del servicio a llamar.
 * @param {Object} [body={}] - Datos que se enviarán en el cuerpo de la solicitud.
 * @param {Function} [dispatch] - Función dispatch de Redux para manejar acciones UI.
 * @param {string} [successMessage] - Mensaje de éxito a mostrar al usuario.
 * @param {string} [errorMessage] - Mensaje de error a mostrar al usuario.
 * @param {string} [method=POST] - Método HTTP que se va a utilizar (opcional, por defecto "POST").
 * @returns {Promise<Object>} - Respuesta del servicio.
 */
const fetchWithFeedback = async ({
  serviceName,
  body = {},
  method = "POST",
  dispatch = null,
  successMessage = null,
  errorMessage = null,
}) => {
  return new Promise((resolve, reject) => {
    callService(
      serviceName,
      body,
      (error, response) => {
        if (!error) {
          resolve(response.responseJSON);

          if (dispatch) {
            dispatch(
              uiActions.showSnackbar(
                successMessage || "Proceso realizado exitosamente"
              )
            );
          }
        } else {
          const rejectBody = {
            message:
              (error.responseJSON && error.responseJSON.body) ||
              "Error desconocido",
          };

          if (dispatch) {
            dispatch(uiActions.showSnackbar(rejectBody.message, "error"));
          }
          reject(rejectBody);
        }
      },
      method
    );
  });
};

const ApiVL = {
  /**
   * Realiza una llamada a un servicio específico con retroalimentación del usuario.
   *
   * @param {string} serviceName - Nombre del servicio a llamar.
   * @param {Object} [body={}] - Datos que se enviarán en el cuerpo de la solicitud.
   * @param {Function} [dispatch] - Función dispatch de Redux para manejar acciones UI.
   * @param {string} [successMessage] - Mensaje de éxito a mostrar al usuario.
   * @param {string} [errorMessage] - Mensaje de error a mostrar al usuario.
   * @param {string} [method=POST] - Método HTTP que se va a utilizar (opcional, por defecto "POST").
   * @returns {Promise<Object>} - Respuesta del servicio.
   */
  fetch: async (config) => {
    return fetchWithFeedback(config);
  },
  /**
   * Realiza una llamada a un servicio en modo bulk con retroalimentación del usuario.
   *
   * @param {string} serviceName - Nombre del servicio a llamar.
   * @param {Array} [body=[]] - Datos que se enviarán en el cuerpo de la solicitud.
   * @param {Function} [dispatch] - Función dispatch de Redux para manejar acciones UI.
   * @param {string} [successMessage] - Mensaje de éxito a mostrar al usuario.
   * @param {string} [errorMessage] - Mensaje de error a mostrar al usuario.
   * @param {string} [method=POST] - Método HTTP que se va a utilizar (opcional, por defecto "POST").
   * @returns {Promise<Object>} - Respuesta del servicio.
   */
  fetchBulk: async (config) => {
    return fetchWithFeedback(config);
  },
};

export default ApiVL;
