export function uiReducer(state = {
  canClose: true,
  isUploaded: false
}, action) {
  switch (action.type) {
    case "SNACKBAR_SHOW":
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarDuration: action.duration,
        snackbarSeverity: action.severity,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        snackbarOpen: false,
      };
    case "UPLOAD_DIALOG_STATUS":
      return {
        ...state,
        canClose: action.canClose
      }
    case "IS_UPLOADED_STATUS":
      return {
        ...state,
        isUploaded: action.isUploaded
      }
    default:
      return state;
  }
}
