import { Button, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo verde.png'
import { ROUTES_DICT } from '../../routes/routesDict'
import { useStyles } from '../LoginPage/styles'

const NotFoundPage = () => {
	const history = useHistory()
	const classes = useStyles()
	return (
		<Container style={{ minHeight: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '14px' }}>
				<img
					src={logo}
					alt="logo"
				/>
			</div>
			<Typography
				variant='h2'
				align='center'

			>
				404 Error
			</Typography>
			<Typography
				variant='h3'
				align='center'

			>
				No se encontró la página
			</Typography>

			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '25px' }}>

				<Grid item xs={3}>
					<Button
						className={classes.recovery}
						onClick={() => {
							history.push(ROUTES_DICT.login);
						}}
					>
						Regresar al Inicio de sesión
					</Button>
				</Grid>
			</div>

		</Container>
	)
}

export default NotFoundPage