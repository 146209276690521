import React from "react";
import { SearchRounded } from "@material-ui/icons";
import { Grid, TextField } from "@material-ui/core";
import { useStyles } from "./styles";

/**
 * @component
 * @description Componente, barra de busqueda de sanidad
 */

const SearchTreatment = ({ setSearchText, searchText }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        container
        alignItems="flex-end"
        justifyContent={"space-between"}
      >
        <Grid item sm={12} xs={12} className={classes.searchInputContainer}>
          <TextField
            variant="filled"
            name={"search"}
            label={"Buscar por identificador o nombre"}
            defaultValue={""}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              disableUnderline: true,
              className: classes.searchInput,
              endAdornment: <SearchRounded className={classes.searchIcon} />,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SearchTreatment;
