/**
 * Realiza una llamada HTTP utilizando fetch API.
 *
 * @param {Object} params - Parámetros de la solicitud HTTP.
 * @param {string} params.method - Método HTTP (e.g., 'POST', 'GET').
 * @param {string} params.url - URL a la que se enviará la solicitud.
 * @param {Object} [params.headers={}] - Encabezados HTTP adicionales.
 * @param {Object} params.body - Cuerpo de la solicitud, convertido a JSON.
 * @param {Function} [cb=() => {}] - Función de callback para manejar la respuesta de la solicitud.
 */
const callHTTPService = (params, cb = () => {}) => {
  params.headers = params.headers || {};
  const fetchOptions = {
    method: params.method,
    headers: {
      ...params.headers,
      "Content-Type": "application/json",
    },
  };

  if (params.method !== "GET" && params.method !== "HEAD") {
    fetchOptions.body = JSON.stringify(params.body);
  }

  fetch(params.url, fetchOptions)
    .then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    )
    .then(({ status, body }) => {
      if (Math.floor(status / 100) === 2) {
        cb(null, { status, responseJSON: body });
      } else {
        cb({ status, responseJSON: body });
      }
    })
    .catch((error) => {
      console.log(error);
      cb({
        status: 500,
        responseJSON: { message: error || "Error desconocido" },
      });
    });
};

/**
 * Llama a un servicio específico en el servidor remoto.
 *
 * @param {string} serviceKey - Clave del servicio que se va a ejecutar.
 * @param {Object} data - Datos que se enviarán en el cuerpo de la solicitud.
 * @param {Function} cb - Función de callback para manejar la respuesta del servicio.
 * @param {string} [method=POST] - Método HTTP que se va a utilizar (opcional, por defecto "POST").
 */
const callService = (serviceKey, data = {}, cb = () => {}, method = "POST") => {
  const remoteServiceHandlerUrl = "https://api.contigopecuario.com";
  callHTTPService(
    {
      method: method,
      url: `${remoteServiceHandlerUrl}/api/${serviceKey}`,
      body: data,
    },
    cb
  );
};

export { callService };
