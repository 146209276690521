import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import SearchVaccination from "../SearchVaccination";

const AddVaccination = ({ searchText, setSearchText }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      <Grid
        container
        item
        xs={12}
        className={classes.registerContainer}
        justifyContent={"space-between"}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          container
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <SearchVaccination searchText={searchText} setSearchText={setSearchText} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddVaccination;
