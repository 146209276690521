import CustomDialog from "../../../../components/CustomDialog";
import TreatmentForm from "../Forms/TreatmentForm";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para poder agregar en sanidad
 */

const TreatmentCreatePage = ({ parentPathname }) => {
  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <TreatmentForm
            type="create"
            onClickCancelButton={props.handleClose}
            onCompleteSubmit={props.handleClose}
          />
        )}
      </CustomDialog>
    </>
  );
};

export default TreatmentCreatePage;
