class UiActions {
  clearSnackbar = () => {
    return (dispatch) => {
      dispatch({ type: "SNACKBAR_CLEAR" });
    };
  };

  showSnackbar = (message, severity = "success", duration = 4000) => {
    return (dispatch) => {
      dispatch({ type: "SNACKBAR_SHOW", message, severity, duration });
    };
  };

  setCanClose = (canClose) => {
    return (dispatch) => {
      dispatch({ type: "UPLOAD_DIALOG_STATUS", canClose: canClose })
    }
  }

  setIsUploaded = (isUploaded) => {
    return (dispatch) => {
      dispatch({ type: "IS_UPLOADED_STATUS", isUploaded })
    }
  }
}

export default new UiActions();
