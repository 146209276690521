import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import VaccinationActions from "../../../../redux/actions/vaccination.actions";

import VaccinationForm from "../Forms/VaccinationForm";

import CustomDialog from "../../../../components/CustomDialog";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para poder editar en sanidad
 */

const VaccinationUpdatePage = ({ parentPathname }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentVaccination = useSelector((state) => state.vaccination.current);

  useEffect(() => {
    if (!currentVaccination || currentVaccination._id !== params._id) {
      dispatch(VaccinationActions.get({ _id: params._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <>
            {currentVaccination &&
              currentVaccination._id &&
              currentVaccination._id === params._id && (
                <VaccinationForm
                  type="update"
                  initValues={currentVaccination}
                  onClickCancelButton={props.handleClose}
                  onCompleteSubmit={props.handleClose}
                />
              )}
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default VaccinationUpdatePage;
