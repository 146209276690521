const ACTION_TYPES = {
  AUTH: {
    REGISTER_SUCESS: "auth/REGISTER_SUCESS",
    REGISTER_FAIL: "auth/REGISTER_FAIL",
    LOGIN_SUCESS: "auth/LOGIN_SUCCESS",
    LOGIN_FAIL: "auth/LOGIN_FAIL",
    LOGOUT: "auth/LOGIN_LOGOUT",
    UPDATE: "auth/UPDATE",
  },
  UI: {
    SNACKBAR_SHOW: "ui/SNACKBAR_SHOW",
  },
  API: {
    REQUEST: "api/REQUEST",
    START: "api/START",
    END: "api/END",
    DENIED: "api/DENIED",
    ERROR: "api/ERROR",
  },
  CURRENCY: {
    RETRIEVE: "currency/RETRIEVE",
    RETRIEVE_BY_ID: "currency/RETRIEVE_BY_ID",
  },
  AGRIBUSINESS: {
    RETRIEVE: "agribusiness/RETRIEVE",
    RETRIEVE_BY_ID: "agribusiness/RETRIEVE_BY_ID",
    CREATE: "agribusiness/CREATE",
    UPDATE_CURRENT: "agribusiness/UPDATE_CURRENT",
    UPDATE: "agribusiness/UPDATE",
    DELETE: "agribusiness/DELETE",
  },
  MOVEMENT: {
    RETRIEVE_LIST: "movement/RETRIEVE_LIST",
    CREATE: "movement/CREATE",
    UPDATE_CURRENT: "movement/UPDATE_CURRENT",
    DELETE: "movement/DELETE",
  },
  FARM: {
    RETRIEVE_BY_OWNER_ID: "farm/RETRIEVE_BY_OWNER_ID",
    UPDATE_LOADING: "farm/UPDATE_LOADING",
    CLEAR_ALL: "farm/CLEAR_ALL",
  },
  ANIMAL: {
    UPDATE_CURRENT: "animal/UPDATE_CURRENT",
    RETRIEVE: "animal/RETRIEVE",
    RETRIEVE_DEADS: "animal/RETRIEVE_DEADS",
    RETRIEVE_BY_CURRENCY: "animal/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "animal/RETRIEVE_BY_ID",
    UPDATE: "animal/UPDATE",
    DELETE: "animal/DELETE",
    CREATE: "animal/CREATE",
  },
  MILKCONTROL: {
    RETRIEVE: "milk/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "milk/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "milk/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "milk/UPDATE_CURRENT",
    CREATE: "milk/CREATE",
    UPDATE: "milk/UPDATE",
    DELETE: "milk/DELETE",
  },
  PALPATION: {
    RETRIEVE: "palpation/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "palpation/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "palpation/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "palpation/UPDATE_CURRENT",
    CREATE: "palpation/CREATE",
    UPDATE: "palpation/UPDATE",
    DELETE: "palpation/DELETE",
  },
  ZEAL: {
    RETRIEVE: "zeal/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "zeal/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "zeal/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "zeal/UPDATE_CURRENT",
    CREATE: "zeal/CREATE",
    UPDATE: "zeal/UPDATE",
    DELETE: "zeal/DELETE",
  },
  WEIGHT: {
    RETRIEVE: "weight/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "weight/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "weight/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "weight/UPDATE_CURRENT",
    CREATE: "weight/CREATE",
    UPDATE: "weight/UPDATE",
    DELETE: "weight/DELETE",
  },
  SALE: {
    RETRIEVE: "sale/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "sale/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "sale/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "sale/UPDATE_CURRENT",
    CREATE: "sale/CREATE",
    UPDATE: "sale/UPDATE",
    DELETE: "sale/DELETE",
  },
  ASSOCIATION: {
    RETRIEVE: "association/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "association/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "association/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "association/UPDATE_CURRENT",
    CREATE: "association/CREATE",
    UPDATE: "association/UPDATE",
    DELETE: "association/DELETE",
  },
  DRYING: {
    RETRIEVE: "drying/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "drying/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "drying/RETRIEVE_BY_ID",
    UPDATE_CURRENT: "drying/UPDATE_CURRENT",
    CREATE: "drying/CREATE",
    UPDATE: "drying/UPDATE",
    DELETE: "drying/DELETE",
  },
  RACE: {
    RETRIEVE: "race/RETRIEVE",
  },
  BIRTH: {
    RETRIEVE_LIST: "birth/RETRIEVE_LIST",
    UPDATE_CURRENT: "birth/UPDATE_CURRENT",
    DELETE: "birth/DELETE",
    CREATE: "birth/CREATE",
  },
  GENETICSTOCK: {
    RETRIEVE: "geneticStock/RETRIEVE",
    RETRIEVE_BY_CURRENCY: "geneticStock/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "geneticStock/RETRIEVE_BY_ID",
    UPDATE: "geneticStock/UPDATE",
    UPDATE_CURRENT: "geneticStock/UPDATE_CURRENT",
    DELETE: "geneticStock/DELETE",
    CREATE: "geneticStock/CREATE",
  },
  SERVICE: {
    RETRIEVE_BY_CURRENCY: "service/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "service/RETRIEVE_BY_ID",
    UPDATE: "service/UPDATE",
    RETRIEVE: "service/RETRIEVE",
    UPDATE_CURRENT: "service/UPDATE_CURRENT",
    DELETE: "service/DELETE",
    CREATE: "service/CREATE",
  },
  COLLABORATOR: {
    RETRIEVE_BY_CURRENCY: "collaborator/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "collaborator/RETRIEVE_BY_ID",
    UPDATE: "collaborator/UPDATE",
    RETRIEVE: "collaborator/RETRIEVE",
    UPDATE_CURRENT: "collaborator/UPDATE_CURRENT",
    DELETE: "collaborator/DELETE",
    CREATE: "collaborator/CREATE",
  },
  COMMENT: {
    RETRIEVE_BY_CURRENCY: "comment/RETRIEVE_BY_CURRENCY",
    RETRIEVE_BY_ID: "comment/RETRIEVE_BY_ID",
    UPDATE: "comment/UPDATE",
    RETRIEVE: "comment/RETRIEVE",
    UPDATE_CURRENT: "comment/UPDATE_CURRENT",
    DELETE: "comment/DELETE",
    CREATE: "comment/CREATE",
  },
  USER: {
    RETRIEVE_BY_ID: "user/RETRIEVE_BY_ID",
    UPDATE: "user/UPDATE",
    RETRIEVE: "user/RETRIEVE",
    UPDATE_CURRENT: "user/UPDATE_CURRENT",
    DELETE: "user/DELETE",
    CREATE: "user/CREATE",
  },
  GRAPHIC: {
    RETRIEVE: "graphic/RETRIEVE",
  },
  MILKGRAPHIC: {
    RETRIEVE: "milkGraphic/RETRIEVE",
  },
  VACCINATION: {
    RETRIEVE: "vaccination/RETRIEVE",
    UPDATE_CURRENT: "vaccination/UPDATE_CURRENT",
    RETRIEVE_BY_ID: "vaccination/RETRIEVE_BY_ID",
    RETRIEVE_BY_CURRENCY: "vaccination/RETRIEVE_BY_CURRENCY",
    CREATE: "vaccination/CREATE",
    UPDATE: "vaccination/UPDATE",
    DELETE: "vaccination/DELETE",
  },
  VACCINATIONPRODUCT: {
    RETRIEVE: "vaccinationProduct/RETRIEVE",
  },
  TREATMENT: {
    RETRIEVE: "treatment/RETRIEVE",
    UPDATE_CURRENT: "treatment/UPDATE_CURRENT",
    RETRIEVE_BY_ID: "treatment/RETRIEVE_BY_ID",
    RETRIEVE_BY_CURRENCY: "treatment/RETRIEVE_BY_CURRENCY",
    CREATE: "treatment/CREATE",
    UPDATE: "treatment/UPDATE",
    DELETE: "treatment/DELETE",
  },
};

export default ACTION_TYPES;
