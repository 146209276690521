import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import AccountForm from "../Forms/AccountForm";
import { useSelector, useDispatch } from "react-redux";
import ButtonFormik from "../../../components/Inputs/ButtonFormik";
import IdeasCloudApi from "../../../helpers/ideascloudApi";
import ApiVL from "../../../helpers/virtualLabsApi";

/**
 * @component
 * @description Componente, este contiene el formulario de cuenta
 * @author Emerson Puma Quispe <emerson.puma@ideascloud.io>
 */

const AccountControlPage = () => {
  const userCurrent = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);  
  const resetPassword = async () => {
    setIsLoading(true);
    const { email } = userCurrent;
    // await IdeasCloudApi.fetch(
    //   "userRecoverPasswordV2",
    //   { email },
    //   dispatch,
    //   "Hemos enviado un correo de confirmación, por favor revise su bandeja de entrada o spam"
    // );
    await ApiVL.fetch({
      serviceName: "user/RecoverPassword",
      body: { email },
      dispatch: dispatch,
      successMessage: "Hemos enviado un correo de confirmación, por favor revise su bandeja de entrada o spam",
    });
    setIsLoading(false);
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "calc(100vh - 4.2rem)",
        marginTop: "4.2rem",
        padding: "2rem 2.5rem 2.5rem",
        position: "relative",
      }}
    >
      <Grid container xs={12}>
        <Typography variant={"h6"} gutterBottom>
          Configuración de Usuario
        </Typography>
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            {userCurrent && (
              <>
                <AccountForm
                  initValues={{ ...userCurrent, profile: "BASIC" }}
                />
                <br />
                <Grid item container justifyContent={"flex-end"} xs={12}>
                  <Grid item xs={4}>
                    <ButtonFormik
                      xs={4}
                      label="Actualizar Contraseña"
                      onClick={resetPassword}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountControlPage;
