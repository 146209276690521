import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import TableButtons from "../../../../components/TableButtons";
import CustomMuiTable from "../../../../components/CustomMuiTable";

import AddVaccination from "../AddVaccination";

import { columns } from "./constants";
import { vaccinationRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../../routes/routesDict";

import VaccinationActions from "../../../../redux/actions/vaccination.actions";

const VaccinationPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listVaccination = useSelector((state) => state.vaccination.list);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    (async () => {
      setTitle("Control de Sanidad");
      setChipList(vaccinationRouteOptions(location));
      if (!listVaccination || listVaccination.length === 0) {
        setIsLoading(true);
        await dispatch(VaccinationActions.list());
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "animalIdentifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <TableButtons
            onClickDeleteButton={(e) => {
              history.push(
                generatePath(ROUTES_DICT.health.vaccination.delete, {
                  ...params,
                  _id: listVaccination[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.vaccination.update, {
                  ...params,
                  _id: listVaccination[dataIndex]._id,
                })
              );
            }}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      <AddVaccination searchText={searchText} setSearchText={setSearchText} />
      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listVaccination}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default VaccinationPageList;
