import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "vaccinationProduct";

const list = () => async (dispatch, getState) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/List`,
    // body: { agribusinessId: agribusiness?._id },
  });
  dispatch({ type: ACTION_TYPES.VACCINATIONPRODUCT.RETRIEVE, payload: response });
};

const VaccinationProductActions = {
  list,
};

export default VaccinationProductActions;
