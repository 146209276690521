import { ROUTES_DICT } from "../../../routes/routesDict";

export const vaccinationRouteOptions = (location) => [
  {
    key: "Inicio",
    path: ROUTES_DICT.health.vaccination.list,
    icon: "home",
  },
  {
    key: "Agregar",
    path: ROUTES_DICT.health.vaccination.create,
    icon: "add",
  },
  // {
  //   key: "Carga Masiva",
  //   path: ROUTES_DICT.animal.createBulk,
  //   icon: "bulk",
  // },
];
