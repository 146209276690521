import React, { useState, useEffect } from "react";
import {
	useHistory,
	useLocation,
	useParams,
	generatePath,
} from "react-router-dom";
import { Button, Chip, Grid, IconButton, Switch, Typography } from "@material-ui/core";
// import AnimalDescription from "../AnimalDescription";
// import AnimalCharts from "../AnimalCharts";
import AddAnimals from "../AddAnimals";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import CustomMuiTable from "../../../components/CustomMuiTable";
import TableButtons from "../../../components/TableButtons";
import { animalRouteOptions } from "../constants";

import { ROUTES_DICT } from "../../../routes/routesDict";
import AnimalActions from "../../../redux/actions/animal.actions";
import { format, isAfter, isBefore } from "date-fns";
import { sexDictionary } from "../../../constants";
import { getAgeInYears } from "../../../helpers/convertDate";
import { DatePicker } from "@material-ui/pickers";
import { statusOptions } from "../../../constants";
import { list } from "../../Services/constants";
import { Visibility } from "@material-ui/icons";



/**
 * @component
 * @description Componente, tabla que contiene la lista de animales
 * @author Frank Cary Viveros <fcaryv@gmail.com>
 */

const AnimalCategoriesPage = ({ children, setTitle, setChipList }) => {
	const history = useHistory();
	const classes = useStyles();
	const params = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const listAnimal = useSelector((state) => state.animal.list);
	const listAnimalDeads = useSelector((state) => state.animal.listDeads);
	const [isLoading, setIsLoading] = useState(false)

	const [searchText, setSearchText] = useState();
	const [listType, setListType] = useState(true);
	const maleBreeding = listAnimal.filter(e => e.status.type === "MALE_BREEDING")
	const maleUpped = listAnimal.filter(e => e.status.type === "MALE_UPPED")
	const maleHeiferNr = listAnimal.filter(e => e.status.type === "MALE_HEIFER_NR")
	const maleHeiferR = listAnimal.filter(e => e.status.type === "MALE_HEIFER_R")
	const maleAnimalR = listAnimal.filter(e => e.status.type === "MALE_ANIMAL_R")
	const males = listAnimal.filter(e => e.gender === "MALE")

	const females = listAnimal.filter(e => e.gender === "FEMALE")
	const femaleBreeding = listAnimal.filter(e => e.status.type === "FEMALE_BREEDING")
	const femaleUpped = listAnimal.filter(e => e.status.type === "FEMALE_UPPED")
	const femaleHeifer = listAnimal.filter(e => e.status.type === "FEMALE_HEIFER")
	const femaleHeiferPregnant = listAnimal.filter(e => e.status.type === "FEMALE_HEIFER_PREGNANT")
	const femaleAnimalBirthed = listAnimal.filter(e => e.status.type === "FEMALE_ANIMAL_BIRTHED")
	const femaleAnimalPregnant = listAnimal.filter(e => e.status.type === "FEMALE_ANIMAL_PREGNANT")
	const femaleAnimalDried = listAnimal.filter(e => e.status.type === "FEMALE_ANIMAL_DRIED")
	const femaleAnimalPregnantDried = listAnimal.filter(e => e.status.type === "FEMALE_ANIMAL_PREGNANT_DRIED")

	const data = [{
		category: "MACHOS",
		total: males.length,
		percentage: males.length / listAnimal.length * 100,
		key: "MALES"
	},
	{
		category: "Cría macho",
		total: maleBreeding.length,
		percentage: maleBreeding.length / males.length * 100,
		key: "MALE_BREEDING"
	},
	{
		category: "Macho levante",
		total: maleUpped.length,
		percentage: maleUpped.length / males.length * 100,
		key: "MALE_UPPED"
	},
	{
		category: "Novillo para engorde",
		total: maleHeiferNr.length,
		percentage: maleHeiferNr.length / males.length * 100,
		key: "MALE_HEIFER_NR"
	},
	{
		category: "Torete",
		total: maleHeiferR.length,
		percentage: maleHeiferR.length / males.length * 100,
		key: "MALE_HEIFER_R"
	},
	{
		category: "Toro reproductor",
		total: maleAnimalR.length,
		percentage: maleAnimalR.length / males.length * 100,
		key: "MALE_ANIMAL_R"
	},
	{
		category: "HEMBRAS",
		total: females.length,
		percentage: females.length / listAnimal.length * 100,
		key: "FEMALES"
	},
	{
		category: "Crías hembra",
		total: femaleBreeding.length,
		percentage: femaleBreeding.length / females.length * 100,
		key: "FEMALE_BREEDING",

	},
	{
		category: "Hembra levante",
		total: femaleUpped.length,
		percentage: femaleUpped.length / females.length * 100,
		key: "FEMALE_UPPED",

	},
	{
		category: "Vaquillona vacía",
		total: femaleHeifer.length,
		percentage: femaleHeifer.length / females.length * 100,
		key: "FEMALE_HEIFER",
	},
	{
		category: "Vaquillona preñada",
		total: femaleHeiferPregnant.length,
		percentage: femaleHeiferPregnant.length / females.length * 100,
		key: "FEMALE_HEIFER_PREGNANT",
	},
	{
		category: "Vaca parida",
		total: femaleAnimalBirthed.length,
		percentage: femaleAnimalBirthed.length / females.length * 100,
		key: "FEMALE_ANIMAL_BIRTHED",
	},
	{
		category: "Vaca preñada",
		total: femaleAnimalPregnant.length,
		percentage: femaleAnimalPregnant.length / females.length * 100,
		key: "FEMALE_ANIMAL_PREGNANT",
	},
	{
		category: "Vaca seca",
		total: femaleAnimalDried.length,
		percentage: femaleAnimalDried.length / females.length * 100,
		key: "FEMALE_ANIMAL_DRIED",
	},
	{
		category: "Vaca preñáda y seca",
		total: femaleAnimalPregnantDried.length,
		percentage: femaleAnimalPregnantDried.length / females.length * 100,
		key: "FEMALE_ANIMAL_PREGNANT_DRIED",
	},
	{
		category: "TOTAL ANIMALES",
		total: listAnimal.length,
		percentage: listAnimal.length / listAnimal.length * 100,
		key: "TOTAL"
	}

	]

	const columns = [
		{
			label: "Sexo/Categoría",
			name: "category",
			options: {
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => ["HEMBRAS", "MACHOS", "TOTAL ANIMALES"].includes(data[dataIndex].category) ? <Chip label={data[dataIndex].category} style={{ color: "white", fontWeight: "bold" }} color="primary" /> : data[dataIndex].category,
			},
		},
		{
			label: "Total",
			name: "total",
			options: {
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => ["HEMBRAS", "MACHOS", "TOTAL ANIMALES"].includes(data[dataIndex].category) ? <Chip label={data[dataIndex].total} style={{ color: "white", fontWeight: "bold" }} color="primary" /> : data[dataIndex].total,
			},
		},
		{
			label: "Porcentaje",
			name: "percentage",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => ["HEMBRAS", "MACHOS", "TOTAL ANIMALES"].includes(data[dataIndex].category) ? <Chip label={data[dataIndex].percentage.toFixed(2)} style={{ color: "white", fontWeight: "bold" }} color="primary" /> : data[dataIndex].percentage.toFixed(2),
			},
		},
		{
			label: "Acciones",
			name: "actions",
			options: {
				searchable: false,
				filter: false,
				sort: false,
				customBodyRenderLite: (dataIndex, rowIndex) => {
					return (
						<IconButton
							style={{ color: "#00a796", backgroundColor: "#bddfdc" }}
							size="small"
							aria-label="delete"


							onClick={() => {
								history.push(
									generatePath(ROUTES_DICT.animal.category, {
										...params,
										type: data[dataIndex].key
									})
								)
							}}
						>
							<Visibility fontSize="small" />
						</IconButton>
						// <Button variant="contained" endIcon={<Visibility />}>
						// </Button>

					);
				},
			},
		}
		// {
		//   label: "Categoría",
		//   name: "category",
		//   options: {
		//     filter: true,
		//     customBodyRender: (value) => categoryOptionsTest[value],
		//     //filterType: "checkbox",
		//     //filterOptions: {
		//     //  names: ["Reproductor", "No Reproductor"],
		//     //},
		//     //searchable: false,
		//     //customBodyRender: (value) =>
		//     //  value ? "Reproductor" : typeof value === "boolean" && "No Reproductor",
		//   },
		// },
	];

	useEffect(() => {
		(async () => {
			setTitle("Categorización");
			setChipList(animalRouteOptions(location));
			if (!listAnimal || listAnimal.length === 0) {
				setIsLoading(true)
				await dispatch(AnimalActions.list());
			}
			// if (!listAnimalDeads || listAnimalDeads.length === 0) {
			// 	setIsLoading(true)
			// 	await dispatch(AnimalActions.listDeads());
			// }
			setIsLoading(false)
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listType]);

	const options = {
		selectableRows: "none",
		search: false,
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		customToolbar: null,
		pagination: false

	};


	// const actionColumn = {
	// 	label: "Acciones",
	// 	name: "actions",
	// 	options: {
	// 		searchable: false,
	// 		filter: false,
	// 		sort: false,
	// 		customBodyRenderLite: (dataIndex, rowIndex) => {
	// 			return (
	// 				<TableButtons
	// 					onClickEyeButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animalDetail.detail, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickDeleteButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.delete, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickEditButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.update, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickStarButton={() => {
	// 						dispatch(
	// 							AnimalActions.update({
	// 								...listAnimal[dataIndex],
	// 								isFeatured: !Boolean(listAnimal[dataIndex]?.isFeatured),
	// 							})
	// 						);
	// 					}}
	// 					starButtonFeatured={listAnimal[dataIndex]?.isFeatured}
	// 				/>
	// 			);
	// 		},
	// 	},
	// };

	return (
		<Grid item container xs={12}>
			{/* <AnimalDescription /> */}
			{/* <AnimalCharts /> */}


			<Grid item xs={12} className={classes.registerContainer}>
				<CustomMuiTable
					// title="Machos"
					data={data}
					columns={columns}
					options={options}
					isLoading={isLoading}
				/>
			</Grid>
			{children()}
		</Grid>
	);
};
export default AnimalCategoriesPage;
