import React, { useState, useEffect } from "react";
import {
	useHistory,
	useLocation,
	useParams,
	generatePath,
} from "react-router-dom";
import { Chip, Grid, IconButton, Switch, Typography } from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import CustomMuiTable from "../../../components/CustomMuiTable";
import { animalRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../routes/routesDict";
import AnimalActions from "../../../redux/actions/animal.actions";
import { Visibility } from "@material-ui/icons";
import { difference } from "lodash";
import { differenceInDays, differenceInMonths } from "date-fns";



/**
 * @component
 * @description Componente, tabla que contiene la lista de animales
 * @author Frank Cary Viveros <fcaryv@gmail.com>
 */

const FemaleClassification = () => {
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();
	const listAnimal = useSelector((state) => state.animal.list);
	const [isLoading, setIsLoading] = useState(false)
	const femaleNovVientre = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0)
	const female12_18 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 12 && differenceInMonths(new Date(), new Date(e.birthDate)) < 18)
	const female18_24 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 18 && differenceInMonths(new Date(), new Date(e.birthDate)) < 24)
	const female24_30 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 24 && differenceInMonths(new Date(), new Date(e.birthDate)) < 30)
	const female30_36 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 30 && differenceInMonths(new Date(), new Date(e.birthDate)) < 36)
	const female36_42 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 36 && differenceInMonths(new Date(), new Date(e.birthDate)) < 42)
	const female42 = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength === 0 && differenceInMonths(new Date(), new Date(e.birthDate)) >= 42)

	const femalesLevante = listAnimal.filter(e => e.status.type === "FEMALE_UPPED")

	const cows = listAnimal.filter(e => e.gender === "FEMALE" && e.birthsLength > 0)
	const females = listAnimal.filter(e => e.gender === "FEMALE")
	const femaleBreeding = listAnimal.filter(e => e.status.type === "FEMALE" && e.birthsLength === 0)
	const femaleFirstPart = listAnimal.filter(e => e.status.type === "FEMALE" && e.birthsLength === 1)
	const femaleSecondPart = listAnimal.filter(e => e.status.type === "FEMALE" && e.birthsLength === 2)
	const femaleThirdPart = listAnimal.filter(e => e.status.type === "FEMALE" && e.birthsLength === 3)
	const femaleMoreParths = listAnimal.filter(e => e.status.type === "FEMALE" && e.birthsLength > 3)


	const data = [
		// 	{
		// 	category: "Hemb. levante",
		// 	total: femalesLevante.length,
		// 	totalPregnants: femalesLevante.filter(e => e.isPregnant).length,
		// 	percentage: femalesLevante.length / listAnimal.length * 100,
		// 	totalGest100: femalesLevante.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
		// 	totalGest100_200: femalesLevante.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
		// 	totalGest200: femalesLevante.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
		// 	// 		customBodyRenderLite: (dataIndex) => `${differenceInDays(new Date(),
		// 	// 			new Date(
		// 	// 				listAnimal[dataIndex].pregnantDate
		// 	// 			)
		// 	// 		)} día/s`
		// 	key: "HEMBRAS_LEVANTE"
		// },
		{
			category: "Nov. vientre(edad meses)",
			total: femaleNovVientre.length,
			totalPregnants: femaleNovVientre.filter(e => e.isPregnant).length,
			percentage: femaleNovVientre.length / femalesLevante.length * 100,
			totalGest100: femaleNovVientre.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: femaleNovVientre.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: femaleNovVientre.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "NOVILLA_VIENTRE"
		},
		{
			category: "➤ >=12 y <18",
			total: female12_18.length,
			percentage: female12_18.length / femalesLevante.length * 100,
			totalPregnants: female12_18.filter(e => e.isPregnant).length,
			totalGest100: female12_18.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female12_18.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female12_18.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE12_18"
		},
		{
			category: "➤ >=18 y <24",
			total: female18_24.length,
			percentage: female18_24.length / femalesLevante.length * 100,
			totalPregnants: female18_24.filter(e => e.isPregnant).length,
			totalGest100: female18_24.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female18_24.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female18_24.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE18_24"
		},
		{
			category: "➤ >=24 y <30",
			total: female24_30.length,
			percentage: female24_30.length / femalesLevante.length * 100,
			totalPregnants: female24_30.filter(e => e.isPregnant).length,
			totalGest100: female24_30.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female24_30.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female24_30.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE24_30"
		},
		{
			category: "➤ >=30 y <32",
			total: female30_36.length,
			percentage: female30_36.length / femalesLevante.length * 100,
			totalPregnants: female30_36.filter(e => e.isPregnant).length,
			totalGest100: female30_36.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female30_36.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female30_36.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE30_36"
		},
		{
			category: "➤ >=36 y <42",
			total: female36_42.length,
			percentage: female36_42.length / femalesLevante.length * 100,
			totalPregnants: female36_42.filter(e => e.isPregnant).length,
			totalGest100: female36_42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female36_42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female36_42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE36_42"
		},
		{
			category: "➤ >=42",
			total: female42.length,
			percentage: female42.length / femalesLevante.length * 100,
			totalPregnants: female42.filter(e => e.isPregnant).length,

			totalGest100: female42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: female42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: female42.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE42"
		},
		{
			category: "Vacas",
			total: cows.length,
			percentage: cows.length / listAnimal.length * 100,
			totalPregnants: cows.filter(e => e.isPregnant).length,
			totalGest100: cows.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: cows.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: cows.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALES"
		},
		// {
		// 	category: "Sin parto anterior",
		// 	total: femaleBreeding.length,
		// 	percentage: femaleBreeding.length / females.length * 100,
		// 	totalPregnants: femaleBreeding.filter(e => e.isPregnant).length,
		// 	totalGest100: femaleBreeding.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
		// 	totalGest100_200: femaleBreeding.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
		// 	totalGest200: femaleBreeding.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
		// 	key: "WITHOUT_BIRTH",

		// },
		{
			category: "➤ 1er parto",
			total: femaleFirstPart.length,
			percentage: femaleFirstPart.length / females.length * 100,
			totalPregnants: femaleFirstPart.filter(e => e.isPregnant).length,
			totalGest100: femaleFirstPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: femaleFirstPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: femaleFirstPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "1PART",

		},
		{
			category: "➤ 2do parto",
			total: femaleSecondPart.length,
			percentage: femaleSecondPart.length / females.length * 100,
			totalPregnants: femaleSecondPart.filter(e => e.isPregnant).length,
			totalGest100: femaleSecondPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: femaleSecondPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: femaleSecondPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "2PART",
		},
		{
			category: "➤ 3er parto",
			total: femaleThirdPart.length,
			percentage: femaleThirdPart.length / females.length * 100,
			totalPregnants: femaleThirdPart.filter(e => e.isPregnant).length,

			totalGest100: femaleThirdPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: femaleThirdPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: femaleThirdPart.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "3PART",
		},
		{
			category: "➤ 4 o mas partos",
			total: femaleMoreParths.length,
			percentage: femaleMoreParths.length / females.length * 100,
			totalPregnants: femaleMoreParths.filter(e => e.isPregnant).length,
			totalGest100: femaleMoreParths.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: femaleMoreParths.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: femaleMoreParths.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE_ANIMAL_BIRTHED",
		},
		{
			category: "Totales",
			total: females.length,
			percentage: females.length / females.length * 100,
			totalPregnants: females.filter(e => e.isPregnant).length,
			totalGest100: females.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 100)).length,
			totalGest100_200: females.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 100) && (differenceInDays(new Date(), new Date(e.pregnantDate)) < 200)).length,
			totalGest200: females.filter(e => e.isPregnant && e.pregnantDate && (differenceInDays(new Date(), new Date(e.pregnantDate)) > 200)).length,
			key: "FEMALE_ANIMAL_BIRTHED",
		},


	]

	const columns = [
		{
			label: "Clasificacion",
			name: "classification",
			options: {
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].category,
			},
		},
		{
			label: "Total hembras",
			name: "totalFemales",
			options: {
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].total,
			},
		},
		{
			label: "Total preñadas",
			name: "totalPregnant",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].totalPregnants,
			},
		},
		{
			label: "Total Vacias",
			name: "totalCows",
			options: {
				searchable: false,
				filter: false,
				sort: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].total - data[dataIndex].totalPregnants,

			},
		},
		{
			label: "% Preñez",
			name: "percentage",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => (((100 / data[dataIndex].total) * (data[dataIndex].totalPregnants)) || 0).toFixed(2) + "%",
			},
		},
		{
			label: "<100 dias gestación",
			name: "gest100",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].totalGest100
			},
		},
		// {
		{
			label: "100-200 dias gestación",
			name: "gest100_200",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].totalGest100_200
			},
		},
		{
			label: "> 200 dias gestación",
			name: "gest200",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex, rowIndex) => data[dataIndex].totalGest200
			},
		},

		//   label: "Categoría",
		//   name: "category",
		//   options: {
		//     filter: true,
		//     customBodyRender: (value) => categoryOptionsTest[value],
		//     //filterType: "checkbox",
		//     //filterOptions: {
		//     //  names: ["Reproductor", "No Reproductor"],
		//     //},
		//     //searchable: false,
		//     //customBodyRender: (value) =>
		//     //  value ? "Reproductor" : typeof value === "boolean" && "No Reproductor",
		//   },
		// },
	];

	// useEffect(() => {
	// 	(async () => {
	// 		setTitle("Categorización");
	// 		setChipList(animalRouteOptions(location));
	// 		if (!listAnimal || listAnimal.length === 0) {
	// 			setIsLoading(true)
	// 			await dispatch(AnimalActions.list());
	// 		}
	// 		// if (!listAnimalDeads || listAnimalDeads.length === 0) {
	// 		// 	setIsLoading(true)
	// 		// 	await dispatch(AnimalActions.listDeads());
	// 		// }
	// 		setIsLoading(false)
	// 	})()
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const options = {
		selectableRows: "none",
		search: false,
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		customToolbar: null,
		pagination: false

	};



	// const actionColumn = {
	// 	label: "Acciones",
	// 	name: "actions",
	// 	options: {
	// 		searchable: false,
	// 		filter: false,
	// 		sort: false,
	// 		customBodyRenderLite: (dataIndex, rowIndex) => {
	// 			return (
	// 				<TableButtons
	// 					onClickEyeButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animalDetail.detail, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickDeleteButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.delete, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickEditButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.update, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickStarButton={() => {
	// 						dispatch(
	// 							AnimalActions.update({import { format, isAfter, isBefore } from "date-fns";

	// 								...listAnimal[dataIndex],
	// 								isFeatured: !Boolean(listAnimal[dataIndex]?.isFeatured),
	// 							})
	// 						);
	// 					}}
	// 					starButtonFeatured={listAnimal[dataIndex]?.isFeatured}
	// 				/>
	// 			);
	// 		},
	// 	},
	// };

	return (
		<>
			<Typography
				color={"primary"}
				align={"center"}
				className={classes.titleGraphics}
			// style={{ fontSize: 30, color: "black", paddingBottom: 20 }}
			>
				Hembras Preñadas
			</Typography>
			<Grid item container xs={12}>
				{/* <AnimalDescription /> */}
				{/* <AnimalCharts /> */}


				<Grid item xs={12} className={classes.registerContainer}>
					<CustomMuiTable
						// title="Machos"
						data={data}
						columns={columns}
						options={options}
						isLoading={isLoading}
					/>
				</Grid>

			</Grid>
		</>
	);
};
export default FemaleClassification;
