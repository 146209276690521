import React, { useState, useEffect } from "react";
import {
	useHistory,
	useLocation,
	useParams,
	generatePath,
} from "react-router-dom";
import { Button, Chip, Grid, IconButton, Switch, Typography } from "@material-ui/core";
import { expectedBirth, getAge } from "../../../helpers/convertDate";
// import AnimalDescription from "../AnimalDescription";
// import AnimalCharts from "../AnimalCharts";
import AddAnimals from "../AddAnimals";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import CustomMuiTable from "../../../components/CustomMuiTable";
import TableButtons from "../../../components/TableButtons";
import { animalRouteOptions } from "../constants";

import { ROUTES_DICT } from "../../../routes/routesDict";
import AnimalActions from "../../../redux/actions/animal.actions";
import { differenceInDays, format, isAfter, isBefore } from "date-fns";
import { sexDictionary } from "../../../constants";
import { getAgeInYears } from "../../../helpers/convertDate";
import { DatePicker } from "@material-ui/pickers";
import { statusOptions } from "../../../constants";
import { list } from "../../Services/constants";
import { Visibility } from "@material-ui/icons";
import { act } from "react-dom/test-utils";



/**
 * @component
 * @description Componente, tabla que contiene la lista de animales
 * @author Frank Cary Viveros <fcaryv@gmail.com>
 */

const AnimalCategoryPage = ({ children, setTitle, setChipList }) => {
	const history = useHistory();
	const classes = useStyles();
	const params = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const listAnimal = useSelector((state) => state.animal.list).filter(e => params.type === "MALES" ? e.gender === "MALE" : params.type === "FEMALES" ? e.gender === "FEMALE" : params.type === "TOTAL" ? true : e.status.type === params.type);
	const listAnimalDeads = useSelector((state) => state.animal.listDeads);
	const [isLoading, setIsLoading] = useState(false)
	const [genderType, setGenderType] = useState(null)

	const [searchText, setSearchText] = useState();




	const columns = [
		{
			label: "ID. del animal",
			name: "identifier",
			options: {
				filter: false,
			},
		},
		{
			label: "Nombre",
			name: "name",
			customBodyRender: (value) => value,
			options: {
				filter: false,
			},
		},
		...((params.type).includes("PREGNANT") ? [{
			label: "Parto esperado",
			name: "estimatedBirthDate",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex) => expectedBirth(listAnimal[dataIndex].pregnantDate)
			}

			//       {expectedBirth(
			//   currentAnimal.pregnantDate
			// )}
		},
		// {
		// 	label: "Dias de preñez",
		// 	name: "pregnantDays",
		// 	options: {
		// 		searchable: false,
		// 		filter: false,
		// 		customBodyRenderLite: (dataIndex) => `${differenceInDays(new Date(),
		// 			new Date(
		// 				listAnimal[dataIndex].pregnantDate
		// 			)
		// 		)} día/s`
		// 	}

		// 	//       {expectedBirth(
		// 	//   currentAnimal.pregnantDate
		// 	// )}
		// },
		{
			label: "Servicios",
			name: "services",
			options: {
				searchable: false,
				filter: false,
				customBodyRenderLite: (dataIndex) => listAnimal[dataIndex].services.length
			}

			//       {expectedBirth(
			//   currentAnimal.pregnantDate
			// )}
		}

		] : []),
		{
			label: "Edad",
			name: "birthDate",
			options: {
				searchable: false,
				filter: false,
				customBodyRender: (value) =>
					value && getAge(new Date(value), new Date()),
			},
		},
		{
			label: "Sexo",
			name: "gender",
			options: {
				searchable: false,
				filterOptions: {
					names: ["Hembra", "Macho"],
				},
				customBodyRender: (value) => sexDictionary[value],
			},
		},
		{
			label: "Estado",
			name: "status",
			options: {
				customBodyRender: (value) => value.esLabel,
			},
		},
		// {
		//   label: "Categoría",
		//   name: "category",
		//   options: {
		//     filter: true,
		//     customBodyRender: (value) => categoryOptionsTest[value],
		//     //filterType: "checkbox",
		//     //filterOptions: {
		//     //  names: ["Reproductor", "No Reproductor"],
		//     //},
		//     //searchable: false,
		//     //customBodyRender: (value) =>
		//     //  value ? "Reproductor" : typeof value === "boolean" && "No Reproductor",
		//   },
		// },
		{
			label: "Entrada hato",
			name: "herdDate",
			options: {
				searchable: false,
				customBodyRender: (value) => {
					// console.log(`value ${value}`);
					// console.log(`format-value ${format(new Date(value), "yyyy-MM-dd")}`);
					// console.log(
					//   `format-value with formatISO ${formatISO(new Date(value), {
					//     representation: "date",
					//   })}`
					// );
					// const peruDate = utcToZonedTime(value, "America/Lima");
					// console.log(
					//   `date-fns-tz ${formatFns(new Date(value), "yyyy-MM-dd", {
					//     timeZone: "America/Lima",
					//   })}`
					// );
					return value ? format(new Date(value), "yyyy-MM-dd") : "-";
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v[0] && v[1]) {
							return `Desde: ${format(v[0], "yyyy-MM-dd")}, Hasta ${format(
								v[1],
								"yyyy-MM-dd"
							)}`;
						} else if (v[0]) {
							return `Desde: ${format(v[0], "yyyy-MM-dd")}`;
						} else if (v[1]) {
							return `Hast: ${format(v[1], "yyyy-MM-dd")}`;
						}
					},
					update: (filterList, filterPos, index) => {
						if (filterPos === 0) {
							filterList[index].splice(filterPos, 1, "");
						} else if (filterPos === 1) {
							filterList[index].splice(filterPos, 1);
						} else if (filterPos === -1) {
							filterList[index] = [];
						}
						return filterList;
					},
				},
				filterOptions: {
					names: [],
					logic(herdDate, filters) {
						if (filters[0] && filters[1]) {
							return (
								isBefore(new Date(herdDate), new Date(filters[0])) ||
								isAfter(new Date(herdDate), new Date(filters[1]))
							);
						} else if (filters[0]) {
							return isBefore(new Date(herdDate), new Date(filters[0]));
						} else if (filters[1]) {
							return isAfter(new Date(herdDate), new Date(filters[1]));
						}
						return false;
					},
					display: (filterList, onChange, index, column) => {
						return (
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<DatePicker
										value={filterList[index][0]}
										onChange={(date) => {
											filterList[index][0] = date;
											onChange(filterList[index], index, column);
										}}
										label="Desde"
										clearable
										format="yyyy-MM-dd"
										clearLabel="LIMPIAR"
										cancelLabel="CANCELAR"
									/>
								</Grid>
								{/* */}
								<Grid item xs={6}>
									<DatePicker
										value={filterList[index][1]}
										onChange={(date) => {
											filterList[index][1] = date;
											onChange(filterList[index], index, column);
										}}
										label="Hasta"
										clearable
										format="yyyy-MM-dd"
										clearLabel="LIMPIAR"
										cancelLabel="CANCELAR"
									/>
								</Grid>
							</Grid>
						);
					},
				},
			},
		},
	];;

	useEffect(() => {
		(async () => {
			const typeLabel = statusOptions[params.type] || (params.type === "MALES" ? "Machos" : params.type === "FEMALES" ? "Hembras" : "Todos")
			setTitle(typeLabel);
			if (["MALES", "MALE_BREEDING", "MALE_UPPED", "MALE_HEIFER_NR", "MALE_HEIFER_R", "MALE_ANIMAL_R"].includes(params.type)) {
				setGenderType("MALE")
			} else if (["FEMALES", "FEMALE_BREEDING", "FEMALE_UPPED", "FEMALE_HEIFER", "FEMALE_HEIFER_PREGNANT", "FEMALE_ANIMAL_BIRTHED", "FEMALE_ANIMAL_PREGNANT", "FEMALE_ANIMAL_DRIED", "FEMALE_ANIMAL_PREGNANT_DRIED"].includes(params.type)) {
				setGenderType("FEMALE")
			} else {
				setGenderType("BOTH")
			}


			setChipList(animalRouteOptions(location));
			if (!listAnimal || listAnimal.length === 0) {
				setIsLoading(true)
				await dispatch(AnimalActions.list());
			}
			// if (!listAnimalDeads || listAnimalDeads.length === 0) {
			// 	setIsLoading(true)
			// 	await dispatch(AnimalActions.listDeads());
			// }
			setIsLoading(false)
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const options = {
		selectableRows: "none",
		searchText,
		search: false,
	};

	const actionColumn = {
		label: "Acciones",
		name: "actions",
		options: {
			searchable: false,
			filter: false,
			sort: false,
			customBodyRenderLite: (dataIndex, rowIndex) => {
				return (
					<TableButtons
						onClickEyeButton={() => {
							history.push(
								generatePath(ROUTES_DICT.animalDetail.detail, {
									...params,
									_id: listAnimal[dataIndex]._id,
								})
							);
						}}
						onClickDeleteButton={() => {
							history.push(
								generatePath(ROUTES_DICT.animal.delete, {
									...params,
									_id: listAnimal[dataIndex]._id,
								})
							);
						}}
						onClickEditButton={() => {
							history.push(
								generatePath(ROUTES_DICT.animal.update, {
									...params,
									_id: listAnimal[dataIndex]._id,
								})
							);
						}}
						onClickStarButton={() => {
							dispatch(
								AnimalActions.update({
									...listAnimal[dataIndex],
									isFeatured: !Boolean(listAnimal[dataIndex]?.isFeatured),
								})
							);
						}}
						starButtonFeatured={listAnimal[dataIndex]?.isFeatured}
					/>
				);
			},
		},
	};


	// const actionColumn = {
	// 	label: "Acciones",
	// 	name: "actions",
	// 	options: {
	// 		searchable: false,
	// 		filter: false,
	// 		sort: false,
	// 		customBodyRenderLite: (dataIndex, rowIndex) => {
	// 			return (
	// 				<TableButtons
	// 					onClickEyeButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animalDetail.detail, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickDeleteButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.delete, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickEditButton={() => {
	// 						history.push(
	// 							generatePath(ROUTES_DICT.animal.update, {
	// 								...params,
	// 								_id: listAnimal[dataIndex]._id,
	// 							})
	// 						);
	// 					}}
	// 					onClickStarButton={() => {
	// 						dispatch(
	// 							AnimalActions.update({
	// 								...listAnimal[dataIndex],
	// 								isFeatured: !Boolean(listAnimal[dataIndex]?.isFeatured),
	// 							})
	// 						);
	// 					}}
	// 					starButtonFeatured={listAnimal[dataIndex]?.isFeatured}
	// 				/>
	// 			);
	// 		},
	// 	},
	// };

	return (
		<Grid item container xs={12}>
			{/* <AnimalDescription /> */}
			{/* <AnimalCharts /> */}

			<AddAnimals searchText={searchText} setSearchText={setSearchText} />

			<Grid item xs={12} className={classes.registerContainer}>
				<CustomMuiTable
					// title="Machos"
					data={listAnimal}
					columns={[...columns, actionColumn]}
					options={options}
					isLoading={isLoading}
				/>
			</Grid>
			{children()}
		</Grid>
	);
};
export default AnimalCategoryPage;
