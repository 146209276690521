import CustomDialog from "../../../../components/CustomDialog";
import VaccinationForm from "../Forms/VaccinationForm";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para poder agregar en sanidad
 */

const VaccinationCreatePage = ({ parentPathname }) => {
  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <VaccinationForm
            type="create"
            onClickCancelButton={props.handleClose}
            onCompleteSubmit={props.handleClose}
          />
        )}
      </CustomDialog>
    </>
  );
};

export default VaccinationCreatePage;
