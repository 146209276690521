import { Grid, Paper, Typography } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { sum } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "../styles";

const DataCard = ({ classes, title, value, subtitle = 'Animales' }) => (
  <Grid item md={3} xs={12}>
    <Paper elevation={1} className={classes.userItemContainer}>
      <Typography
        color={"primary"}
        align={"center"}
        className={classes.titleGraphics2}
      >
        {title}
      </Typography>
      <Typography
        color={"primary"}
        align={"center"}
        className={classes.userItemNumber}
      >
        {value}
      </Typography>
      <Typography
        variant={"body2"}
        align={"center"}
        className={classes.userItemText}
      >
        {subtitle}
      </Typography>
    </Paper>
  </Grid>
);

const Reproduction = () => {
  const classes = useStyles();
  const animalList = useSelector((state) => state.animal.list);
  const birthList = useSelector((state) => state.birth.list);
  const listWeightControl = useSelector((state) => state.weight.list);
  const listZealControl = useSelector((state) => state.zeal.list);
  const listPalpationControl = useSelector((state) => state.palpation.list);
  const listDryingControl = useSelector((state) => state.drying.list);

  // const listAnimalDeads = useSelector((state) => state.animal.listDeads);

  const openDays = () => {
    const filterD = animalList
      .filter((e) => e.births && e.births.length > 0)
      .map((e) => {
        const diference = differenceInDays(
          new Date(),
          new Date(e.births[0]?.birthDate)
        );
        return diference;
      });
    return (sum(filterD) / filterD.length).toFixed(0) || 0;
  };

  const maleBirth = () => {
    const mal = animalList.filter((e) => e.gender === "MALE");
    const filterD = animalList.filter(
      (e) => e.gender === "MALE" && e.status.type === "MALE_BREEDING"
    );
    return ((filterD.length * 100) / mal.length).toFixed(0) || 0;
  };

  const birthRate = () => {
    const birth = birthList.filter((e) => e.birthType !== "ABORTION");
    return ((birth.length * 100) / birthList.length).toFixed(0) || 0;
  };

  const birthNotRate = () => {
    const birth = birthList.filter((e) => e.birthType === "ABORTION");
    return ((birth.length * 100) / birthList.length).toFixed(0) || 0;
  };

  const firstBirth = () => {
    const fml = animalList.filter((e) => e.gender === "FEMALE");
    const fmlBirth = animalList.filter((e) => e.birthsLength > 0);

    return ((fmlBirth.length * 100) / fml.length).toFixed(0) || 0;
  };

  const weightBirth = () => {
    const filterWeigt = listWeightControl
      .filter((e) => e.controlType === "BIRTH")
      .map((e) => e.weight);
    console.log(filterWeigt);
    return (sum(filterWeigt) / filterWeigt.length).toFixed(0) || 0;
  };

  const femalePregnant = () => {
    const female = animalList.filter((e) => e.gender === "FEMALE");
    const animalFilter = animalList.filter((e) => e.isPregnant);

    return ((animalFilter.length * 100) / female.length).toFixed(0) || 0;
  };

  const obsZeal = () => {
    const filterZeal = listZealControl.filter(
      (e) => e.observation && e.observation !== "" && e.observation !== " "
    );
    return ((filterZeal.length * 100) / listZealControl.length).toFixed(0) || 0;
  };

  const prenatalMortality = () => {
    const filterPalpation = listPalpationControl.filter(
      (e) => e.state === "EMPTY"
    );
    return (
      ((filterPalpation.length * 100) / listPalpationControl.length).toFixed(
        0
      ) || 0
    );
  };

  const dryingMortality = () => {
    const filter = listDryingControl.filter((e) => e.reason === "ABORTION");
    return ((filter.length * 100) / listDryingControl.length).toFixed(0) || 0;
  };

  const animalPerService = () => {
    const avg = [];
    animalList
      .filter((e) => e.services && e.services.length > 0)
      .forEach((a) => {
        avg.push(a.services.length);
      });
    return (sum(avg) / avg.length).toFixed(0) || 0;
  };

  const animalPerIA = () => {
    const filter = animalList.filter(
      (e) =>
        e.isPregnant &&
        e.services &&
        e.services.length > 0 &&
        e.services[0].serviceType === "AR_IN"
    );
    return filter.length || 0;
  };

  const animalPerMN = () => {
    const filter = animalList.filter(
      (e) =>
        e.isPregnant &&
        e.services &&
        e.services.length > 0 &&
        e.services[0].serviceType === "NA_MO"
    );
    return filter.length || 0;
  };

  const animalPerTE = () => {
    const filter = animalList.filter(
      (e) =>
        e.isPregnant &&
        e.services &&
        e.services.length > 0 
    );

    console.log(filter)
  
    return filter.length || 0;
  };

  return (
    <>
      <Typography
        color={"primary"}
        align={"center"}
        className={classes.titleGraphics}
        // style={{ fontSize: 30, color: "black", paddingBottom: 20 }}
      >
        Reproducción
      </Typography>
      <Grid item container xs={12} justifyContent="space-around">
        <DataCard classes={classes} title={'#Días Abiertos'} value={openDays()} subtitle="Días" />
        <DataCard classes={classes} title={'#Machos Nacidos'} value={maleBirth()} />
        <DataCard classes={classes} title={'#Natalidad'} value={birthRate()} />
        <DataCard classes={classes} title={'#Pesos al nacer'} value={weightBirth()}  subtitle="Kg"/>
        <DataCard classes={classes} title={'#Vacas Preñadas'} value={`${femalePregnant()} %`} />
        <DataCard classes={classes} title={'#Primer Parto'} value={`${firstBirth()} %`} />
        <DataCard classes={classes} title={'#Serv. x Vaca'} value={animalPerService()} subtitle="Servicios"/>
        <DataCard classes={classes} title={'#Vacas Obs. Celo'} value={`${obsZeal()} %`}/>
        <DataCard classes={classes} title={'#Vaca Preñada x Inseminación Artificial'} value={animalPerIA()}/>
        <DataCard classes={classes} title={'#Vaca Preñada x Monta Natural'} value={animalPerMN()}/>
        {           
        //  <DataCard classes={classes} title={'#Vaca Preñada x Transferencia de Embriones'} value={animalPerTE()}/>
        }
      </Grid>
    </>
  );
};

export default Reproduction;
