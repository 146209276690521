import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useStyles } from "../../styles";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import uiActions from "../../redux/actions/ui.actions";

const CustomDialog = ({
  children,
  parentPathname,
  maxWidth = "sm",
  action,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const canClose = useSelector((state) => state.ui.canClose);
  const isUploaded = useSelector((state) => state.ui.isUploaded);
  const [showPrevent, setShowPrevent] = useState(false)
  const dispatch = useDispatch()
  const handleClose = (inSub = false) => {
    if (!canClose) {

      return
    }
    console.log(`isUploaded ${isUploaded}`)
    console.log(`inSub ${!inSub}`)
    if (isUploaded && !inSub) {
      console.log("entro al prevent")

      setShowPrevent(true)
      return
    }
    if (action) {
      action();
    }
    if (
      location.state &&
      location.state.background &&
      location.state.background.pathname !== location.pathname
    ) {
      history.push(generatePath(location.state.background.pathname, params));
    } else if (parentPathname) {
      history.push(generatePath(parentPathname, params));
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <Dialog
        open
        fullWidth
        classes={{ paperFullWidth: classes.customModal }}
        onClose={handleClose}
        maxWidth={maxWidth}
      >
        {!canClose && (
          <Alert style={{ marginBlock: '20px' }} severity="warning">No se puede cerrar la ventana hasta que la acción culmine </Alert>
        )}
        <Close onClick={() => handleClose()} className={classes.customModal__closeBtn} />
        {children({ handleClose })}
      </Dialog>
      {showPrevent && (
        <Dialog
          open={showPrevent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alerta"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estas seguro que deseas cerrar esta ventana?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={async () => {
              setShowPrevent(false)
              dispatch(uiActions.setIsUploaded(false))
              console.log(`isUploaded on SUbdialog ${isUploaded}`)
              handleClose(true)
            }} color="primary">
              Si
            </Button>
            <Button onClick={() => setShowPrevent(false)} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

    </>
  );
};

export default CustomDialog;
