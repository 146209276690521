import React, { useState, useEffect } from "react";
import { Chip, Dialog, Grid, Typography, IconButton } from "@material-ui/core";
import { Close, Edit, Delete } from "@material-ui/icons";
// import { useHistory } from "react-router-dom";
import clsx from "clsx";
import CustomMuiTable from "../../components/CustomMuiTable";
import { useStyles } from "./styles";
import { userColumns } from "./constants";
import FormUser from "./FormProfile";

function ProfilesControlPage({ children, setTitle, setChipList }) {
  const classes = useStyles();
  // const history = useHistory();
  // const { location = {} } = history;
  const [open, setOpen] = useState(false);
  const [searchText] = useState();

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <>
            <IconButton
              style={{ color: "#C25560" }}
              size="small"
              aria-label="edit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              style={{ color: "#C25560" }}
              size="small"
              aria-label="delete"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </>
        );
      },
    },
  };

  useEffect(() => {
    setTitle("Configuración Perfiles");
  }, []);

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>        
        <CustomMuiTable
          data={[]}
          columns={[...userColumns, actionColumn]}
          options={options}
        />
      </Grid>
      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.modal }}
      >
        <Close className={classes.closeBtn} onClick={() => setOpen(false)} />

        <FormUser setOpen={setOpen} />
      </Dialog>
    </Grid>
  );
}

export default ProfilesControlPage;
