import { CircularProgress } from "@material-ui/core";
import { differenceInDays, parseISO } from "date-fns";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import authActions from "../redux/actions/auth.actions";
import { farmActions } from "../redux/actions/farm.actions";
import { useStyles } from "../styles";
import { ROUTES_DICT } from "./routesDict";
import { useHistory } from "react-router-dom";
import { difference } from "lodash";

/**
 * @returns {Component}
 * @description Wrapper de autentificacion que solicita datos globales de prioridad (Farm actual y Agronegocio actual)
 * @author Frank Cary Viveros <frank.cary@tecsup.edu.pe>
 */

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const currentFarm = useSelector((state) => state.farm.current);
  const classes = useStyles();
  const location = useLocation();
  // const history = useHistory();

  const { current: currentAgribusiness } = useSelector(
    (state) => state.agribusiness
  );

  const user = useSelector((state) => state.auth.user);
  const history = useHistory()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      if (!(user.dateLogin && differenceInDays(new Date(), parseISO(user.dateLogin)) < 30)) {
        dispatch(authActions.logout());
        history.push(ROUTES_DICT.login);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    if (!currentFarm || !currentAgribusiness) {
      dispatch(farmActions.findFarmByOwnerId(user?._id)).then((e) => {
        //if (!currentFarm) {
        //  history.push(ROUTES_DICT.setup);
        //}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFarm, currentAgribusiness, user]);
  return (
    <>
      {!currentAgribusiness &&
        isLoggedIn &&
        location.pathname !== ROUTES_DICT.setup ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default AuthWrapper;
