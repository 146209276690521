import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import TreatmentActions from "../../../../redux/actions/treatment.actions";

import TreatmentForm from "../Forms/TreatmentForm";

import CustomDialog from "../../../../components/CustomDialog";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para poder editar en sanidad
 */

const TreatmentUpdatePage = ({ parentPathname }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentTreatment = useSelector((state) => state.treatment.current);

  useEffect(() => {
    if (!currentTreatment || currentTreatment._id !== params._id) {
      dispatch(TreatmentActions.get({ _id: params._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <>
            {currentTreatment &&
              currentTreatment._id &&
              currentTreatment._id === params._id && (
                <TreatmentForm
                  type="update"
                  initValues={currentTreatment}
                  onClickCancelButton={props.handleClose}
                  onCompleteSubmit={props.handleClose}
                />
              )}
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default TreatmentUpdatePage;
