import ApiVL from "../helpers/virtualLabsApi";

const prefixRoute = "user";

class AuthService {
  // login(data) {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService("userLogin", data, function (error, response) {
  //       if (!error) {
  //         localStorage.setItem("user", JSON.stringify(response.responseJSON));
  //         resolve(response.responseJSON);
  //       } else {
  //         const rejectBody = {
  //           message:
  //             (error.responseJSON && error.responseJSON.body) ||
  //             "Error desconocido",
  //         };
  //         reject(rejectBody);
  //       }
  //     });
  //   });
  // }

  async login(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/Login`,
        body: data,
      });
      localStorage.setItem("user", JSON.stringify(response));
      return response;
    } catch (error) {
      // console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  // loginWithGoogle(data) {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "userLoginWithGoogle",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           localStorage.setItem("user", JSON.stringify(response.responseJSON));
  //           resolve(response.responseJSON);
  //         } else {
  //           const rejectBody = {
  //             message:
  //               (error.responseJSON && error.responseJSON.body) ||
  //               "Error desconocido",
  //           };
  //           reject(rejectBody);
  //         }
  //       }
  //     );
  //   });
  // }

  async loginWithGoogle(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/LoginWithGoogle`,
        body: data,
      });
      localStorage.setItem("user", JSON.stringify(response));
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  async register(userData) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/Register`,
        body: userData,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  async sendVerificationEmail({ email }, fullUrl) {
    const baseUrl = fullUrl.split("#")[0] + "#/email-verified/";
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/SendVerificationEmail`,
        body: { email, baseUrl },
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }
}

export default new AuthService();
