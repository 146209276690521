// import { alpha, createTheme } from "@material-ui/core";
// import { useTheme } from "@material-ui/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

/**
 * @param {MaterialTableProps} props
 *
 */
function CustomMuiTable({ options, isLoading, ...props }) {
  const baseOptions = {
    textLabels: {
      pagination: {
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        next: "Anterior página",
        previous: "Siguiente página",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      body: {
          noMatch: isLoading ?
              <>
                <CircularProgress/>
                <Typography>Cargando...</Typography>
              </> :
              'No se encontraron registros',
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
    },
  };
  return (
    <div>
      <MUIDataTable  options={{ ...baseOptions, ...options }} {...props} />
    </div>
  );
}

export default CustomMuiTable;
